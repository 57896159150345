import React, { useEffect } from "react";
// import axios from 'axios';
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";

function BodyA({ data }) {
  const cls = useRptAF();
  const iInfo = JSON.parse(localStorage.getItem("iInfos")) || {};
  // const [state, setState] = useState({});
  // const getInfo = async () => {
  // axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
  // }
  var columns = [
    // { title: " م", field: "" },
    { title: "كود الحساب", field: "acc_no" },
    { title: "اسم الحساب", field: iInfo.type == 1 ? "name" : "acc_name" },
    { title: "مدين", field: "dept" },
    { title: "دائن", field: "credit" },
    {
      title: "البيان",
      field: "notes",
      cellStyle: {
        padding: "5px 0px",
        textAlign: "-webkit-center",
        margin: "0px",
        // whiteSpace: "nowrap",
        width: "150px",

        border: "solid 0.1px",
        fontFamily: "Amiri-Regular",
      },
    },
  ];
  useEffect(() => {
    /*getInfo(); */
  }, []);

  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
          <MaterialTable
            style={{ boxShadow: "none" }}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              paging: false,
              headerStyle: stElemnt.AF.header,
              cellStyle: stElemnt.AF.cell,
              maxBodyHeight: "32.5rem",
              minBodyHeight: "32.5rem",
            }}
            localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
            columns={columns}
            data={data}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ paddingTop: 5, color: "#f00", fontSize: "16px" }}
        >
          <Grid item xs={4}>
            {"الاجمالي  "}
          </Grid>
          <Grid item style={{ textAlign: "center" }} xs={4}>
            {"مدين:  "}
            {iInfo.total_credit}
          </Grid>
          <Grid item xs={4}>
            {"دائن:  "}
            {iInfo.total_dept}
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={10} /*className={cls.rootchip}*/></Grid>
    </Grid>
  );
}

export default BodyA;
