import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'

import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import BarcodeGenerator from './BarcodeGenerator';
import Button from '../../../../../components/controls/Button';
import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useEffect } from 'react';


const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center',
        border:'1px solid #000',
        backgroundColor:'#eee'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    }
}))

class ComponentToPrint extends React.Component {
  state = {
    pwca:true,
}
  constructor(){ super();}



  render() {

    const styleTyg = { 
      // backgroundColor: '#fff', 
      color :'#000',
        padding:'0.2mm' ,
      };

    return (
        <Typography component="div" style={styleTyg}>
            <BarcodeGenerator barcode={this.props.data.barcode} 
            itemName={this.props.data.symbol}
            price={this.props.data.sale_price}
            
            />
        </Typography>
    );
  }
}

export default function BarcodeDialog(props) {

    const { open, data , setOpen } = props;
      const componentRef = useRef();
    const classes = useStyles()

    return (
        <Dialog open={open} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant="h5">
                    {'شاشة طباعة الباركود'}
                </Typography>
                <br/>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <ComponentToPrint ref={componentRef} {...{data}}/>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>

                <Button
                    text="إغلاق"
                    color="secondary"
                    onClick={() => 
                    setOpen(false)}
                     />
                <ReactToPrint trigger={() => <Button
                    text="طباعة"
                    color="secondary"
                    /> } 
                    content={() => componentRef.current} />
                
            </DialogActions>
        </Dialog>
    )
}