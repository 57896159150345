// const KEYS = {
//     restrictions: 'restrictions',
//     restrictionId: 'restrictionId'
// }
import moment from "moment";
import { sto } from "../Context/InshData";
import { GetItemFromOptions } from "./multi-receipt-services";
import { getAccountOmlat } from "./omlat-services";

export const restrictDefaultValue = {
  id: 0,
  date: new moment(new Date()).format("YYYY-MM-DD"),
  doc_no: -1,
  type: 10,
  state: 1,
  notes: "",
  branch: 0,
  IS_Deleted: "",
  omla: "",
  omlaval: "",
  doc_no2: "",
  total_dept: 0,
  total_credit: 0,
  total_difference: 0,
  rest_type: 1,
  rest_cycle_no: 0,
  book_id: "",
  Center: -1,
};

export const openingRestDV = {
  ...restrictDefaultValue,
  type: 11,
  id: 0,
  doc_no2: 0,
  state: 1,
  branch: sto.branchi,
  omla: -1,
  omlaval: 1,
};

export const subDefaultValues = {
  res_id: "",
  acc_no: "",
  name: "",
  account: { id: "", name: "" },
  acc_omlat: [],
  acc_omla: { id: "-1", name: "", exchange: 1 },
  credit: 0,
  credit_loc: 0,
  dept: 0,
  dept_loc: 0,
  refno: "",
  value: 0,
  Center: { id: -1, name: "" },
  notes: "",
};

export function getAmountNumber(num) {
  let newNum = num.toString() == "" ? "0" : num;

  return parseFloat(newNum).toFixed(2).toString();
}

export const restrictStates = [
  { id: 1, name: "", label: "معتمد" },
  { id: 2, name: "", label: "لاغي/ معلق" },
];

export function PrepareData(data) {
  let restriction = {
    id: data?.id,
    omla: -1,
    omlaval: 1,
    doc_no2: -1,
    Center: data.center?.id ?? -1,
    notes: data?.notes,
    type: 11,
    state: 1,
    date: data.date,
    branch: data.branch,
    total_credit: data?.rows
      ?.reduce((a, v) => (a = a + v.credit_loc), 0)
      .toFixed(2),
    total_dept: data?.rows
      ?.reduce((a, v) => (a = a + v.dept_loc), 0)
      .toFixed(2),
  };
  let _Data = data?.rows?.map((row) => {
    return {
      dept: row.dept,
      credit: row.credit,
      acc_no: row.acc_no,
      notes: row.notes,
      branch: data.branch,
      center: row.center?.id,
      refno: row.refno,
      omla: row?.acc_omla?.id,
      omlaval: row?.acc_omla?.exchange,
    };
  });

  return { restriction, _Data };
}
function reFormatingRows(rows, AutoOptions) {
  const accounts = AutoOptions?.Accounts;
  var id = 1;
  let nRows = rows?.map((r) => {
    const account = GetItemFromOptions(r?.acc_no, accounts);
    const center = GetItemFromOptions(r?.center, AutoOptions?.costcenters);
    const omla = GetItemFromOptions(r?.omla, AutoOptions.omlats);
    const cd = r.credit / r.omlaval;
    const dp = r.dept / r.omlaval;
    return {
      ...r,
      // RestDate: "",
      center: center,
      id: id++,
      name: account?.name,
      credit_loc: cd,
      dept_loc: dp,
      account: account,
      acc_omla: { ...omla, exchange: r.omlaval },
      acc_omlat: getAccountOmlat(account?.acc_omlat, AutoOptions.omlats),
    };
  });
  return nRows;
}

export function ReFormatingData(data, AutoOptions) {
  let nData = {
    ...data?.restriction,
    rows: reFormatingRows(data?._Data, AutoOptions),
    date: moment(data?.restriction?.date).format("YYYY-MM-DD"),
  };

  return nData;
}

// export const getDepartmentCollection = () => [
//   { id: "1", title: "Development" },
//   { id: "2", title: "Marketing" },
//   { id: "3", title: "Accounting" },
//   { id: "4", title: "HR" },
// ];

// export function insertRestriction(data) {
//   let restrictions = getAllRestrictions();
//   data["id"] = generateRestrictionId();
//   restrictions.push(data);
//   localStorage.setItem(KEYS.restrictions, JSON.stringify(restrictions));
// }

// export function updateRestriction(data) {
//   let restrictions = getAllRestrictions();
//   let recordIndex = restrictions.findIndex((x) => x.id == data.id);
//   restrictions[recordIndex] = { ...data };
//   localStorage.setItem(KEYS.restrictions, JSON.stringify(restrictions));
// }

// export function deleteRestriction(id) {
//   let restrictions = getAllRestrictions();
//   restrictions = restrictions.filter((x) => x.id != id);
//   localStorage.setItem(KEYS.restrictions, JSON.stringify(restrictions));
// }

// export function generateRestrictionId() {
//   if (localStorage.getItem(KEYS.restrictionId) == null)
//     localStorage.setItem(KEYS.restrictionId, "0");
//   var id = parseInt(localStorage.getItem(KEYS.restrictionId));
//   localStorage.setItem(KEYS.restrictionId, (++id).toString());
//   return id;
// }

// export function getAllRestrictions() {
//   if (localStorage.getItem(KEYS.restrictions) == null)
//     localStorage.setItem(KEYS.restrictions, JSON.stringify([]));
//   let restrictions = JSON.parse(localStorage.getItem(KEYS.restrictions));
//   //map departmentID to department title
//   let departments = getDepartmentCollection();
//   return restrictions.map((x) => ({
//     ...x,
//     department: departments[x.departmentId - 1].title,
//   }));
// }
