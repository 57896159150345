import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableToolbar } from "material-table";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { headerH } from "./restrictionInsert-style";

export default function RestricSubTable(props) {
  const { alldata, setAlldata, loading } = props;

  const handleRowDelete = (event, rowData) => {
    let _data = [...alldata];
    rowData.forEach((rd) => {
      _data = _data.filter((t) => t.tableData.id !== rd.tableData.id);
    });

    setAlldata(_data);
  };

  const cellS = {
    padding: "6.9px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "nowrap",
    width: "0px",
    color: "#000",
    marginLeft: 2,
    marginRight: 2,
  };


  var columns = [
    { title: " م", field: "name" },
    { title: "كود الحساب", field: "acc_no" },
    { title: "اسم الحساب", field: "acc_name" },
    { title: "مدين", field: "dept" },
    { title: "دائن", field: "credit" },
    { title: "مركز التكلفة", field: "centerView" },
    { title: "المرجع", field: "ref_no" },
    { title: "التاريخ", field: "RestDate" },
  ];

  return (
    <Grid item container>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <MaterialTable
            // title="Select row(s) to get the option to delete"
            style={{ boxShadow: "none", direction: "rtl" }}
            ocalization={{
              header: {
                actions: "إجراء",
              },
              body: {
                emptyDataSourceMessage: "بيانات فارغة",
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            options={{
              toolbar: true,

              search: false,
              sorting: false,
              showTitle: false,
              paging: false,
              selection: true,
              showSelectAllCheckbox: false,
              headerStyle: headerH,
              cellStyle: cellS,
              maxBodyHeight: "35vh",
              minBodyHeight: "30vh",
            }}
            components={{
              Toolbar: (props) => (
                <Fragment>
                  <div
                    // className={clstoolbar.toolbarWrapper}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "6.1vh",
                    }}
                  >
                    <MTableToolbar
                      {...props}
                      searchFieldStyle={{ height: "6vh" }}
                    />
                  </div>
                </Fragment>
              ),
            }}
            actions={[
              {
                icon: () => <DeleteOutline />,
                tooltip: "Delete Rows",
                onClick: handleRowDelete,
              },
            ]}
            columns={columns}
            data={alldata}
          />
        )}
      </Grid>
    </Grid>
  );
}
