import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";

import LoadingPage from "../../../../services/LoadingPage";
// import Footer from "./Footer";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { sto } from "../../../../Context/InshData";
import HeaderA from "./HeaderA";
import BodyA, { Totals } from "./BodyA";
import FooterA from "./FooterA";
import { Typography } from "@material-ui/core";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { Rept } from "../../../../Context/InshData";
import useChunkMyData from "../../../../components/Hooks/useChunkMyData";

function OnePage(props) {
  const { found, chunk, isFirst, isLast } = props;
  return (
    <Typography component="div" style={stElemnt.AF.Reviws.Typogmain}>
      <Typography component="div" style={{ ...stElemnt.AF.Reviws.Typogsub }}>
        {isFirst && <HeaderA found={found} />}
        <BodyA
          data={chunk ?? []}
          isFullPage={!isFirst && !isLast ? true : false}
        />
        {isLast && <Totals data={chunk ?? []} />}
        <FooterA found={found} />
      </Typography>
    </Typography>
  );
}

function ReviewAccountContainer(props) {
  const [found, setFound] = useState({});
  const [loading, setLoading] = useState(true);

  const { chunks, chunksCount } = useChunkMyData(Rept.data, 12, 18);
  // const [chunks, setChunks] = useState(_.chunk(Rept.data, 14));
  const firstChunk = _.chunk(Rept.data, 12);

  const getInfo = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        setFound(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setTimeout(async () => {
      await getInfo();

      setLoading(false);
      // onUnmountComplete();
    }, 0);
  }, []);

  if (!found || loading || !chunks) return <LoadingPage />;

  return (
    <Fragment>
      {chunks.map((page, i) => {
        return (
          <OnePage
            {...{
              found,
              chunk: page,
              isFirst: i == 0,
              isLast: i == chunksCount - 1,
            }}
          />
        );
      })}
      {/* <OnePage {...{ found, chunk: Rept.data }} /> */}
    </Fragment>
  );
}
export default ReviewAccountContainer;
