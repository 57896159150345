import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../Label/Lbl";
import BackdropScI from "../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableBody, MTableBodyRow } from "material-table";
// Ust
import { useInvAF, useInvMain } from "../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../St/Reviews/cStyle";
import Paper from "@material-ui/core/Paper";

const Body = () => {
  const clasm = useInvMain();
  const clas = useInvAF();
  // const clasB = useInvBodyAF();

  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);

  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };

    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        console.log(res.data);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        console.log("catch");
      });
  };

  useEffect(() => {
    // alert('getInfo')

    getInfo();
  }, []);
  const cellS = {
    padding: "6.9px 2px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    // textAlign: '-webkit-right',
    border: "solid 0.1px",
    width: "200px",
    // color: '#000',
    fontFamily: "Amiri-Regular",
    // border:'solid 1px red',
  };
  var columns = [
    { title: "", field: "id" },
    { title: "الباركود", field: "barcode" },
    { title: txtA.itemName, field: "symbol", cellStyle: cellS },
    {
      title: txtA.unitPrice,
      field: "new_exchange_price",
      cellStyle: stElemnt.AF.cell,
    },
    { title: txtA.Quantity, field: "val1" },
    { title: txtA.taxableAmount, field: "new_exchange_price" },
    { title: txtA.discount, field: "discount" },
    // { title: txtA.taxRate, field: "taxperc" },
    { title: txtA.taxAmount, field: "taxval" },
    { title: txtA.subTotalWithVat, field: "tot_net_curr" },
  ];

  const data = sta.info.map((row, i) => {
    return { ...row, id: i + 1 };
  });
  return showSc ? (
    <BackdropScI />
  ) : (
    <Grid
      item
      container
      className={clas.Bdyroot}
      style={{
        direction: "rtl",
        // position: "relative",
        // top: "28mm",
        // // height: "360px",
        // width: "100%",
        // pageBreakAfter: "always",
      }}
    >
      <Grid item xs={12}>
        {locInv.ProcTypeInvProc != "03" && (
          <Grid item container className={clas.BdyundrHeaderTF}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8} container className={clas.BdytitleHed}>
              {/* <Typography className={clasm.minclr}>{txtA.titleFatora}</Typography> */}
              <Grid item xs={6} className={clasm.minclr}>
                {sta.tot.tax_no_cust === ""
                  ? "فاتورة ضريبية مبسطة"
                  : "فاتورة ضريبية"}
              </Grid>
              <Grid item xs={6} className={clasm.minclr}>
                {sta.tot.tax_no_cust === ""
                  ? "Simplified Tax Invoice"
                  : "Tax Invoice"}
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        )}

        <Grid item container className={clas.BdyundrHeader}>
          <Grid item xs={2}></Grid>
          <Grid item container xs={8} className={clas.BdytitleHed}>
            <Grid item xs={6} className={clasm.minclr}>
              {locInv.invN +
                " " +
                sta.tot.inv_type_name +
                " " +
                sta.tot.pay_type_name}
            </Grid>
            <Grid item xs={6} className={clasm.minclr}>
              {locInv.invNEn +
                " " +
                sta.tot.inv_type_name_en +
                " " +
                sta.tot.pay_type_name_en}
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid item container className={clasm.mincntr}>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.invNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.id}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invNumr}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.invRef}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.refno}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invRef}
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.invDate}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>
                  {moment(sta.tot.date).format("YYYY/MM/DD")}
                </Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.invDate}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.custmName}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.cust_name}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.custmName}
              </Grid>

              <Grid item xs={3} className={clas.BdyborBtR}>
                {txtE.custAddress}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.cust_address}</Paper>
              </Grid>
              <Grid item xs={3} className={clas.BdyborBtL}>
                {txtA.custAddress}
              </Grid>

              <Grid item xs={3} className={clasm.BdyborBtR}>
                {txtE.taxNumr}
              </Grid>
              <Grid item xs={6} className={clasm.minborB}>
                <Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper>
              </Grid>
              <Grid item xs={3} className={clasm.BdyborBtL}>
                {txtA.taxNumr}
              </Grid>
              <Grid item xs={3} className={clasm.minborR}>
                {"Com.Register"}
              </Grid>
              <Grid item xs={6}>
                <Paper className={clasm.minclr}>{sta.tot?.com_register}</Paper>
              </Grid>
              <Grid item xs={3} className={clasm.minborL}>
                {"السجل التجاري"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} className={clas.BdymtTabl}>
          <MaterialTable
            style={{ boxShadow: "none" }}
            //style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              // body
              // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
              // footer
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              paging: false,
              headerStyle: stElemnt.AF.header,
              cellStyle: stElemnt.AF.cell,
            }}
            localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>

      {/* <Grid container>
    </Grid> */}

      <Grid
        item
        container
        className={clas.BdytotTitle}
        // style={{ pageBreakAfter: "always" }}
      >
        <Grid item xs={6} className={clasm.minlft}>
          {txtA.totalAmount}
        </Grid>
        <Grid item xs={6} className={clasm.minrit}>
          {txtE.totalAmount}
        </Grid>
      </Grid>

      <Grid
        item
        container
        className={clas.BdytotSub}
        // style={{ pageBreakAfter: "always" }}
      >
        <Grid item xs={5} className={clas.BdytotLf}>
          {txtA.amountWithVat}
        </Grid>
        <Grid item xs={5} className={clas.BdytotCntr}>
          {txtE.amountWithVat}
        </Grid>
        <Grid item xs={2} className={clas.BdytotRit}>
          {sta.tot.amountWithVat}
        </Grid>

        <Grid item xs={5} className={clas.BdytotLf}>
          {txtA.discount}
        </Grid>
        <Grid item xs={5} className={clas.BdytotCntr}>
          {txtE.discount}
        </Grid>
        <Grid item xs={2} className={clas.BdytotRit}>
          {sta.tot.discount}
        </Grid>

        <Grid item xs={5} className={clas.BdytotLf}>
          {txtA.amountWithVat}
        </Grid>
        <Grid item xs={5} className={clas.BdytotCntr}>
          {txtE.amountWithVat}
        </Grid>
        <Grid item xs={2} className={clas.BdytotRit}>
          {sta.tot.amountWithVat}
        </Grid>

        <Grid item xs={5} className={clas.BdytotLf}>
          {txtA.amountOfVat}
        </Grid>
        <Grid item xs={5} className={clas.BdytotCntr}>
          {txtE.amountOfVat}
        </Grid>
        <Grid item xs={2} className={clas.BdytotRit}>
          {sta.tot.amountOfVat}
        </Grid>

        <Grid item xs={5} className={clas.BdytotLf}>
          {txtA.tot_net}
        </Grid>
        <Grid item xs={5} className={clas.BdytotCntr}>
          {txtE.tot_net}
        </Grid>
        <Grid item xs={2} className={clas.BdytotRit}>
          {sta.tot.tot_net}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Body;
