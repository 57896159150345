import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../Label/Lbl";
import BackdropScI from "../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
// Ust
import { useInvAF } from "../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";
import InvBarcode from "./Bardode";
import { sto } from "../../../Context/InshData";

const useStyles = makeStyles((theme) => ({
  minclr: {
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    textAlign: "center",
  },
  txt: {
    fontFamily: "Amiri-Regular",
  },

  BdymtTabl: {
    // border: "solid 0.1px",
    borderRadius: "6px",
    padding: 1,
    margin: 7,
    marginBottom: 0,
  },
  BdytotTitle: {
    border: "solid 0.1px black",
    // borderRadius: "6px",
    textAlign: "center",
    // color: "#be151f",
    fontWeight: "bold",
    // margin: 1,
  },
  totalItem: {
    padding: 2,
    border: "solid 0px",

    fontFamily: "Amiri-Regular",
  },
}));

const Body = () => {
  const clas = useInvAF();
  const calssBody = useStyles();
  // const clasB = useInvBodyAF();

  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const secondColor = "#be151f";

  const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);

  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };
    console.log(tb);
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        console.log(res.data);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        console.log("catch");
      });
  };

  useEffect(() => {
    // alert('getInfo')
    console.log(sta.tot);

    getInfo();
  }, []);
  const cellS = {
    padding: "6.9px 2px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    // textAlign: '-webkit-right',
    border: "solid 0.1px",
    // borderRight: 'solid 0px',
    width: "200px",
    // color: '#000',
    fontFamily: "Amiri-Regular",
    // border:'solid 1px red',
  };
  var columns = [
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          وصف المنتج<div style={{ marginTop: -5, paddingTop: 0 }}> (Item)</div>
        </div>
      ),
      field: "symbol",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          العيار<div style={{ marginTop: -5, paddingTop: 0 }}> (Karat)</div>
        </div>
      ),
      field: "nameEN",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          وزن الذهب<div style={{ marginTop: -5, paddingTop: 0 }}> (Weight)</div>
        </div>
      ),
      field: "val1",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          ما خلا من المعدن
          <div style={{ marginTop: -5, paddingTop: 0 }}> (Non Metal)</div>
        </div>
      ),
      field: "zero",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          سعر الجرام
          <div style={{ marginTop: -5, paddingTop: 0 }}> (Gram Price)</div>
        </div>
      ),
      field: "new_exchange_price",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الإجمالي
          <div style={{ marginTop: -5, paddingTop: 0 }}> (Total)</div>
        </div>
      ),
      field: "totBill",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الضريبة<div style={{ marginTop: -5, paddingTop: 0 }}> Vat</div>
        </div>
      ),
      field: "taxval",
    },
    {
      title: (
        <div style={{ margin: 0, padding: 0 }}>
          الإجمالي شامل الضريبة
          <div style={{ marginTop: -5, paddingTop: 0 }}> Total With Vat</div>
        </div>
      ),
      field: "tot_net_curr",
      headerStyle: { borderRight: "solid 0px" },
    },
  ];

  return showSc ? (
    <BackdropScI />
  ) : (
    <Container>
      <Grid
        item
        container
        className={clas.Bdyroot}
        style={{ direction: "rtl", paddingBottom: "5rem" }}
      >
        <Grid container item xs={12} style={{ marginTop: "0.2rem" }}>
          <Grid
            xs={5}
            item
            container
            style={{ paddingTop: 10, textAlign: "center" }}
          >
            <Grid
              item
              xs={6}
              className={calssBody.txt}
              style={{ fontWeight: "bold" }}
            >
              اسم العميل
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {txtA.custmName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              className={calssBody.txt}
              style={{ fontWeight: "bold" }}
            >
              نوع الفاتورة
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {locInv.invN +
                  " " +
                  sta.tot.inv_type_name +
                  " " +
                  sta.tot.pay_type_name}
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid xs={2} item style={{ textAlign: "center" }}>
            <InvBarcode />
          </Grid>
          <Grid
            xs={5}
            item
            container
            style={{
              paddingTop: 10,
              paddingLeft: 10,
              textAlign: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                تاريخ الفاتورة
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {moment(sta.tot.date).format("YYYY/MM/DD")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              رقم الفاتورة
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>{sta.tot.id}</Typography>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {locInv.ProcTypeInvProc != "03" && (
              <Typography
                className={calssBody.minclr}
                variant="h5"
                gutterBottom
              >
                {/* {info.nameA} */}
                {sta.tot.tax_no_cust === ""
                  ? "فاتورة ضريبية مبسطة"
                  : "فاتورة ضريبية"}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12} className={calssBody.BdymtTabl}>
            <MaterialTable
              style={{ boxShadow: "none" }}
              //style={{direction:'ltr'}}
              options={{
                showTitle: false,
                toolbar: false,
                sorting: false,
                selection: false,
                // ToolBar Search
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                // ToolBar header
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                // body
                // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
                // footer
                pageSize: 5,
                paginationType: "stepped",
                showFirstLastPageButtons: false,

                paging: false,
                headerStyle: {
                  ...stElemnt.AF.header,
                  // backgroundColor: colors.grey[100],
                  fontWeight: "1000",
                  fontSize: 15,
                  padding: "1px 1px",
                  // border: "solid 0px",
                  // borderBottom: "solid 0.1px",
                  // borderRight: "solid 0.1px",
                },
                cellStyle: { ...stElemnt.AF.cell, fontWeight: "bold" },
              }}
              localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
              columns={columns}
              data={sta.info?.map((row) => {
                return { ...row, zero: "0.00" };
              })}
            />
          </Grid>
        </Grid>

        {/* <Grid container>
    </Grid> */}

        <Grid item container className={clas.BdytotSub}>
          <Grid
            item
            xs={8}
            container
            className={calssBody.totalItem}
            spacing={1}
          >
            <Grid item xs={4} className={calssBody.BdytotTitle}>
              {sta.tot.amountWithVat}
            </Grid>
            <Grid item xs={7} className={calssBody.BdytotTitle}>
              {" الإجمالي  " + " " + txtE.totalAmount}
            </Grid>
            <Grid item xs={4} className={calssBody.BdytotTitle}>
              {sta.tot.amountOfVat}
            </Grid>
            <Grid item xs={7} className={calssBody.BdytotTitle}>
              {txtA.amountOfVat + " " + txtE.amountOfVat}
            </Grid>
            <Grid item xs={4} className={calssBody.BdytotTitle}>
              {sta.tot.tot_net}
            </Grid>
            <Grid item xs={7} className={calssBody.BdytotTitle}>
              {" الإجمالي مع الضريبة  " + " " + "Total Amount With Vat"}
            </Grid>
          </Grid>
          <Grid item xs={4} container style={{ textAlign: "center" }}>
            ملاحظات الفاتورة
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{ paddingTop: 20, fontWeight: "bold", textAlign: "center" }}
          >
            <Grid item xs={6}>
              {" "}
              {" اسم البائع"}
            </Grid>
            <Grid item xs={6}>
              {"مدير الفرع" + "     " + "  "}
            </Grid>
            <Grid item xs={6}>
              {sto.usernames}
            </Grid>
            <Grid item xs={6}>
              ____________________
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Body;
