import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useStylesTF, useStylesTD } from "../../../../St/comps/UseStyle";

export default function GroupsForm(props) {
  const { object, groups, onChange, Employees } = props;
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const onChangeData = (prop) => (event) => {
    onChange(event, { name: prop, value: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"name"}
          label={" مجموعة الصنف "}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"name"}
          value={object.name}
          onChange={onChangeData("name")}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"nameEN"}
          label={"المجموعة (EN)"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"nameEN"}
          value={object.nameEN}
          onChange={onChangeData("nameEN")}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <RadioGroup
          row
          onChange={onChangeData("type")}
          value={object.type}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="main" control={<Radio />} label="رئيسية " />
          <FormControlLabel value="sub" control={<Radio />} label="فرعية " />
        </RadioGroup>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <Autocomplete // classes={clasAo}
          value={object.parent}
          onChange={(event, newValue) => {
            onChange(null, { name: "parent", value: newValue });
          }}
          id="controllable-states-dem"
          size="small"
          options={groups}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          //style={{  }}  // disabled={}
          disabled={object.type == "main"}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="valuePricePay"
              {...params}
              label={"المجموعة الأب "}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple // classes={clasAo}
          value={object?.group_Emps}
          onChange={(event, newValue) => {
            const index = object?.group_Emps?.findIndex(
              (x) => x.name == event.target.textContent
            );
            // console.log("event", index);
            if (index == -1)
              onChange(null, { name: "group_Emps", value: newValue });
          }}
          id="controllable-states-dem"
          size="small"
          options={Employees}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ direction: "ltr" }} // disabled={}
          //disabled={statu Dis.pricePay}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="valuePricePay"
              {...params}
              label={"عدم الإظهار على الموظف"}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
    </React.Fragment>
  );
}
