import React, { useEffect, useState } from "react";
import {
  Button,
  CardActionArea,
  CardContent,
  colors,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import BalanceCustRPTForm from "./balanceCustRPT-form";
import { useHistory } from "react-router-dom";

import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../St/Reports/UseStyle";
import moment from "moment";
import { insh as inshst } from "../../../Context/InshSt";
import { SAdefaultValues } from "./balanceCustRPT-services";
import BalanceCustRPTTable from "./balanceCustRPT-table";
import { URL } from "../../../api/customer";
import axios from "axios";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#faf1f1",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },

  headerTitle: {
    backgroundColor: "#ffffff",
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  blCard: {
    backgroundColor: "#fff",
    borderColor: "#81007f",
  },
  root3: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: colors.red[300],
    direction: "rtl",
    marginTop: 5,
    padding: 5,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
  },
}));

export default function BalanceCustRPT(props) {
  const { AutoOptions } = props;
  const [rptParams, setRptParams] = React.useState({
    ...SAdefaultValues,
    account: AutoOptions?.Accindex[0],
  });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchDataClick = async () => {
    setFetching(true);
    console.log("the type is", rptParams?.type);
    await axios
      .get(
        URL +
          `/getBalance?type=${rptParams.type?.id}
    &&branch=${rptParams.branch.id}
    &&center=${rptParams.center?.id}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&acc_code=${rptParams.account?.id}`
      )
      .then((res) => {
        let nData = res?.data?.map((d) => {
          return {
            ...d,
            balance: (d?.end_Dept + d?.end_Credit).toFixed(2),
            state: d?.end_Dept > 0 ? "مدين" : "دائن",
          };
        });
        setData(nData);
      });
    setFetching(false);
  };

  const [style, setStyle] = useState(inshst.custm);
  const { t } = useTranslation(["forms"]);
  useEffect(() => {
    console.log(" the balance ", data);
  }, []);
  const his = useHistory();
  const toBack = () => {
    his.push({ pathname: "/" });
  };
  const printHandle = () => {
    const info = {
      data: data,
      type: rptParams?.type?.id,
      tot: data?.totals,
      from: rptParams?.type?.id == 1 ? "العميل" : "العميل - المورد ",
      title: "أرصدة حسابات العملاء / الموردين",
      account: rptParams.account,
      fDate: rptParams.dateFrom,
      tDate: rptParams.dateTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: true,
      //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
    };
    // localStorage.setItem('iInfos',  JSON.stringify(info));
    his.push({ pathname: "/CustBalance", state: info });
    window.location.reload(false);
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography size="small" className={classes.headerTitle}>
          ارصدة حساب العملاء / الموردين
        </Typography>
      </Grid>
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: inshst.colors.cust,
            borderBottomWidth: "10px",
            borderBottomStyle: "solid",
          }}
        >
          <BalanceCustRPTForm
            {...{
              AutoOptions,
              rptParams,
              setRptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...style.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={fetchDataClick}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...style.iconBtn, borderRadius: 0 }}
                // disabled={true}
                onClick={printHandle}
              >
                {t("forms:print")}{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...style.iconBtn, borderRadius: 0 }}
                onClick={toBack}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            // paddingTop:'5px',
          }}
        >
          <Grid container>
            {fetching ? (
              <TableContainer component={Paper}>
                {"يرجى الانتظار قليلا حتى يتم جلب البيانات ..."}
                <LinearProgress />
              </TableContainer>
            ) : (
              <BalanceCustRPTTable {...{ data: data ? data : [] }} />
            )}
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
