import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import {useStylesTF,useStylesTD, useStylesSetting} from '../../../St/comps/UseStyle';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import Swipeable from '../../../Fronts/Main/Swipeable';
import axios from 'axios';
import toast from 'react-hot-toast';
import { uri } from '../../../help/Api';

export default function DFSettingsForm(props){
    const { 
      object,
      data,
      onChange,
      } = props;
      
      const [submiting, setSubmiting ] = useState(false);

  const applyTaxGroup = async(taxGroupId)=>{

   console.log(taxGroupId)
   const data ={id: taxGroupId}
    setSubmiting(true);
    await axios.post(uri+'SysSettings', data).then(res=>{
      toast.success('تم تطبيق الضريبة الافتراضية على جميع الاصناف');

    }).catch(err=>{
      toast.error('لم يتم التطبيق حدثت مشكلة');
      console.log(err)
    });
    
    setSubmiting(false);
  }

    const classesStt = useStylesSetting();
    
    return(
        <React.Fragment>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete // classes={clasAo}
            value={object?.branch}
            onChange={(event, newValue) => { 
                onChange(null, {name:'branch', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.branches}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الفرع الإفتراضي"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete // classes={clasAo}
            value={object.safe}
            onChange={(event, newValue) => { 
                onChange(null, {name:'safe', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.safes}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"المخزن الافتراضي"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
         <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete // classes={clasAo}
            value={object.stock}
            onChange={(event, newValue) => { 
                onChange(null, {name:'stock', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.stocks}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الصندوق الإفتراضي"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete // classes={clasAo}
            value={object?.unit}
            onChange={(event, newValue) => { 
                onChange(null, {name:'unit', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.units}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"الوحدة الإفتراضية"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        <Grid container item lg={12} md={12} xs={12}>
           <Grid item lg={6} md={6} sm={6} xs={6}>
              <Autocomplete // classes={clasAo}
                value={object?.taxGroup}
                onChange={(event, newValue) => { 
                    onChange(null, {name:'taxGroup', value: newValue});
                }}
                id="controllable-states-dem" size="small"
                options={data?.taxGroups}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.nameAR)}
                //style={{  }}  // disabled={}
                
                disableClearable
                renderInput={(params) => <TextField id="valuePricePay" {...params} label={"المجموعة الضريبية الإفتراضية"} 
                variant="outlined" size="small" 
                      />}
                    />
        </Grid> 
        <Grid item  lg={6} md={6} sm={6} xs={6}>
    
          <Button variant="contained" className={classesStt.btn}  
            color="primary" fontSize="large" 
             onClick={()=>{
              if(object?.taxGroup?.id)
                applyTaxGroup(object?.taxGroup?.id);

            }}
            disabled={submiting}
            >
            {submiting?'جاري تطبيق الضريبة  ...':'تطبيق الضريبة على الاصناف'}
            </Button>
        </Grid>
        </Grid>
       
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete // classes={clasAo}
            value={object?.omla}
            onChange={(event, newValue) => { 
                onChange(null, {name:'omla', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={data?.omlat}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"العملة الإفتراضية"} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid> 
        
      </React.Fragment>
    )
    
}