import { useState, useEffect } from "react";
import * as _ from "lodash";

export default function useChunkMyData(data, firstPageLength, chunk) {
  const [chunks, setChunks] = useState();

  function chunckData() {
    if (data?.length > firstPageLength) {
      console.log("is length bigger", data?.length);

      const first = _.chunk(data, firstPageLength);
      const chunks = _.chunk(data.slice(firstPageLength + 1), chunk);
      setChunks([first[0], ...chunks]);
    } else {
      setChunks(_.chunk(data, firstPageLength));
    }
    console.log("is length bigger", data?.length);
  }

  useEffect(() => {
    chunckData();

    return () => {};
  }, []);

  return { chunks, chunksCount: chunks?.length };
}
