import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function SaleAnalysisTable(props) {
  const { data, title } = props;
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{ backgroundColor: colors.green[300], color: "#fff" }}
          >
            <TableCell style={{ color: "#fff" }} align="center">
              {t(title)}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("invoices")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("net-quantity")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("net-total")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("net-discount")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("net-tax")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("net-cost")}
            </TableCell>
            <TableCell style={{ color: "#fff" }} align="center">
              {t("net-profit")}
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.txtType}</TableCell>
              <TableCell align="center">{row.cnt}</TableCell>
              <TableCell align="center">{row.qty}</TableCell>
              <TableCell align="center">{row.totNet}</TableCell>
              <TableCell align="center">{row.minus}</TableCell>
              <TableCell align="center">{row.vat}</TableCell>
              <TableCell align="center">{row.cost}</TableCell>
              <TableCell align="center">{row.Profit}</TableCell>
              <TableCell align="center">{}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
