import React, { useEffect } from 'react';
// import axios from 'axios';
// import {uri}  from '../../../../help/Api';
import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import Typography from '@material-ui/core/Typography';
import {useRptAF} from '../../../../St/Reviews/UseStyle';
// import { Paper } from '@material-ui/core';
import MaterialTable from "material-table";
import {stElemnt} from '../../../../St/Reviews/cStyle';

function BodyA() {
  const cls = useRptAF();
  // const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  // const [state, setState] = useState({});
  // const getInfo = async () => {
  // axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
// }

useEffect(() => { /*getInfo(); */ }, []);


return (
<Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
        <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            // body
            // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
            // footer
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: stElemnt.AF.header,
            cellStyle: stElemnt.AF.cell,
            // maxBodyHeight: '28.5rem',
            // minBodyHeight: '28.5rem',
           }}
             
          localization={{ body:{ emptyDataSourceMessage:<h1></h1> ,}, }}
          
              columns={Rept.columns}
              data={Rept.data}
             />
        </Grid>
      </Grid>

      <Grid container lg={12} md={12} className={cls.rootBdydown}>
         <Grid container xs={10} /*className={cls.rootchip}*/>
            <Grid item xs={5} className={[cls.chipT,cls.chipTR]}>{'إجمالي عدد الأصناف'}</Grid>
            <Grid item xs={4} className={[cls.chipV,cls.chipVR]}>{Rept.tot.totval}</Grid>
          </Grid>
          <Grid container xs={10} /*className={cls.rootchip}*/>
            <Grid item xs={5} className={[cls.chipT,cls.chipTR]}>{'إجمالي التكلفة'}</Grid>
            <Grid item xs={4} className={[cls.chipV,cls.chipVR]}>{Rept.tot.totTaqlfa}</Grid>
          </Grid>
      </Grid>

</Grid>
   );

  }

export default BodyA;
