import axios from "axios";
import { uri } from "../help/Api";

export const RefreshBillSettings = async () => {
  await axios
    .get(uri + `SettInvsApp`)
    .then((res) => {
      let data = {
        id: 1,
        dsplyColmDiscount: res.data.dsplyColmDiscount,
        dsplyColmExclVAT: res.data.dsplyColmExclVAT,
        dsplyColmSerialnumb: res.data.dsplyColmSerialnumb,
        dsplyHeadFootr: res.data.dsplyHeadFootr,
        dsplyHedrSalesOrUser: res.data.dsplyHedrSalesOrUser,
        dsplyHedrdir: res.data.dsplyHedrdir,
        colmNoA: res.data.colmNoA,
        colmProductDescA: res.data.colmProductDescA,
        colmUnitA: res.data.colmUnitA,
        colmQtyA: res.data.colmQtyA,
        colmUnitPriceA: res.data.colmUnitPriceA,
        colmAmountA: res.data.colmAmountA,
        colmDiscountA: res.data.colmDiscountA,
        colmValueExclVatA: res.data.colmValueExclVatA,
        colmVatValueA: res.data.colmVatValueA,
        colmValueIncludeVatA: res.data.colmValueIncludeVatA,
        colmNoE: res.data.colmNoE,
        colmProductDescE: res.data.colmProductDescE,
        colmUnitE: res.data.colmUnitE,
        colmQtyE: res.data.colmQtyE,
        colmUnitPriceE: res.data.colmUnitPriceE,
        colmAmountE: res.data.colmAmountE,
        colmDiscountE: res.data.colmDiscountE,
        colmValueExclVatE: res.data.colmValueExclVatE,
        colmVatValueE: res.data.colmVatValueE,
        colmValueIncludeVatE: res.data.colmValueIncludeVatE,
        // tot
        sumcolmTax: res.data.sumcolmTax,
        sumcolmDiscount: res.data.sumcolmDiscount,
        sumcolmNetAmount: res.data.sumcolmNetAmount,
        sumcolmTotal: res.data.sumcolmTotal,
        sasInvHeadr: res.data.sasInvHeadr,
        sasInvFootr: res.data.sasInvFootr,
        amountWithVatA: res.data.amountWithVatA,
        amountWithVatE: res.data.amountWithVatE,
        totdiscountA: res.data.totdiscountA,
        totdiscountE: res.data.totdiscountE,
        amountWithVat2A: res.data.amountWithVat2A,
        amountWithVat2E: res.data.amountWithVat2E,
        amountOfVatA: res.data.amountOfVatA,
        amountOfVatE: res.data.amountOfVatE,
        tot_netA: res.data.tot_netA,
        tot_netE: res.data.tot_netE,
        showColDHd: res.data.showColDHd,
        ncol: res.data.ncol,
        titleFatoraA: res.data.titleFatoraA,
        titleFatoraE: res.data.titleFatoraE,
      };

      localStorage.setItem("billSTGs", JSON.stringify(res.data));
      return data;
    })
    .catch((error) => {});
};
