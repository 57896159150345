import React, { useState, useEffect } from "react";
import { txtA } from "../../../Label/Lbl";
import axios from "axios";
import { uri } from "../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvCa, useInvMain } from "../../../St/Reviews/UseStyle";
import { makeStyles } from "@material-ui/core/styles";
import { sto } from "../../../Context/InshData";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    fontSize: "0.6rem",
    //  fontWeight:'700',
    fontWeight: "bold",
  },
  table: {
    minWidth: 650,
  },
  allWidth: {
    fontSize: "0.8rem",
    //  fontWeight:'700',
    fontWeight: "bold",
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));
function Header(props) {
  const clasm = useInvMain();
  const clas = useInvCa();
  //  const clasH = useInvHeadrCa();
  const { onUnmountComplete } = props;
  const classes = useStyles();

  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = {
      GetFoundation: true,
      parms: { iFoundid: 1, branch: sto.branchi },
      autos: {},
    };
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
        console.log("the res is ", res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      onUnmountComplete();
    }, 0);
  }, []);

  return (
    <Grid item container>
      <Grid item xs={12} className={clasm.mincntr}>
        <Typography>
          <ButtonBase>
            {" "}
            <img
              src={`data:image/jpeg;base64,${info.Logo}`}
              className={clas.Hedlogo}
              alt={info.nameA}
            />{" "}
          </ButtonBase>
        </Typography>
      </Grid>

      <Grid item xs={12} className={[clasm.mincntr]}>
        <Typography className={classes.allWidth}>
          <b>{info.nameA}</b>
        </Typography>
        {/* <Typography >{info.nameE}</Typography> */}
      </Grid>

      {/*  */}

      {/* <Grid item xs={5} className={[clasm.minrit]}>
        <Typography className={clas.contnt}>{info.Mobile}</Typography>
       </Grid> */}

      {/* <Grid item xs={7} className={[clasm.mincntr]}>
        <Typography className={[clas.contnt,clas.colr]} >{' : ' + txtA.taxphone+''+txtA.found}</Typography>
       </Grid> */}
      {/* <Grid item xs={12} className={[clasm.mincntr]}>
        <Typography className={clas.contntSub}>{info.Address}</Typography> 
       </Grid> */}
      <Grid item xs={6}>
        <Typography className={classes.allWidth}>
          <b>{info.tax_no}</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.itemW}>
          <b>{txtA.taxNumr + "" + txtA.found} </b>
        </Typography>
      </Grid>
    </Grid>
  );
}
export default Header;
