import React, { useState, useEffect } from "react";
import clsx from "clsx";
// import {uri, }  from '../../help/Api';
import { sto, insh, premiScr } from "../../Context/InshData";
import { inshs } from "../../Context/Local";
// import axios from 'axios';
// import dataF from '../../Server/dataF.json';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Paper from "@material-ui/core/Paper";

import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import ShopOutlinedIcon from "@material-ui/icons/ShopOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import TimelineIcon from "@material-ui/icons/Timeline";
import ArrowLeftRoundedIcon from "@material-ui/icons/ArrowLeftRounded";
import RateReviewIcon from "@material-ui/icons/RateReview";
//for Sale
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ListSubheader from "@material-ui/core/ListSubheader";
import SettingsIcon from "@material-ui/icons/Settings";
import { useHistory } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import "./Slude.css";
import icondrawer from "../../assets/img/apple-icon.png";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import { Link } from "react-router-dom";
import { getLS } from "../../utils/useLS";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";
import i18next from "i18next";
import SelectLanguage, {
  languageOptions,
} from "../../components/SelectLanguage";
import { TextField } from "@material-ui/core";
import Refresh from "@material-ui/icons/Refresh";
import { useRefresh } from "../../comps/Settings/ReData";
import LoadingPage, { LoadingRefresh } from "../../services/LoadingPage";
import { Toaster } from "react-hot-toast";
import { ClearAll, DeleteOutline } from "@material-ui/icons";
import DeleteConfirmDialog from "../../components/DeleteConfirmDialog";
import { useBackupDB } from "../../comps/Settings/BackupDB";

// import DatePicker from '../../componentsT/controls/DatePicker';

const useStyles = makeStyles((theme) => ({
  img: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    // width: theme.spacing(7),
    // height: theme.spacing(7),
    height: "20%",
    width: "20%",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  // swip
  appBar: {
    top: "auto",
    bottom: 0,
    borderRadius: "15px 15px 0px 0px",
    backgroundImage: "linear-gradient(to right, #4e9e84 , #2688ba)",
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  button: {
    margin: theme.spacing(0.5),
    color: "#fff",
    backgroundColor: "#008081",
    boxShadow: "none",
  },
}));

const backg = {
  color: "#9c27b0",
  // boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)',
  // backgroundColor: '#9c27b0',
};
const csal = { color: "#008001" };
const csafs = { color: "#010080" };
const cacc = { color: "#808080" };
const ccust = { color: "#fff" };
const cchart = { color: "#fff" };
const chome = { color: "#fff" };
const ctaq = { color: "#fff" };
const csett = { color: "#fff" };

const styles = {
  paperContainer: {
    backgroundImage: `url(${icondrawer})`,
    height: "50px",
    boxShadow: "none",
    width: "50px",
    marginLeft: "2rem",
    backgroundSize: "cover",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let f = false,
  t = true;

export default function Swipeable() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [lang, setLang] = useState(i18next.language);

  // const [showSc, setShowSc] = useState(true);
  const [state, setState] = React.useState({ right: false });
  let his = useHistory();

  const [dateTime, setDateTime] = useState(new Date());

  const locConn = JSON.parse(localStorage.getItem("infoConn")) || {};

  // const [riv, setRiv] = useState(allRiv);
  const [sting, setSting] = useState("false");

  // s dialg
  const [openInfo, setOpenInfo] = React.useState(false);

  const clickinfoOpen = () => {
    setOpenInfo(true);
  };
  const clickinfoClose = () => {
    setOpenInfo(false);
  };
  const [prmiScr, setPermiScr] = useState(insh.prmiScr);
  let pScr = getLS("premiScr", {});

  useEffect(async () => {
    setPermiScr(premiScr);
    if (sto.User_typei === 1) {
      setSting("true");
    }
    const id = setInterval(() => setDateTime(new Date()), 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  function refreshPage() {
    his.push({ pathname: "/" });
    window.location.reload(false);
  }

  const inshState = {
    server: locConn.server,
    ipinsh: locConn.ipinsh,
    changip: locConn.changip,
    ip: locConn.ip,
    endDigtip: locConn.endDigtip,
    namedb: locConn.namedb,
    id: locConn.id,
    pass: locConn.pass,
    useUri: locConn.useUri,
    port: locConn.port,
    ftp: locConn.ftp,
    typeConnct: locConn.typeConnct,
    mode: locConn.mode,
    leavle: "signIn",
  };

  const refDatas_out = () => {
    localStorage.removeItem("dataShamel");
    let loc = JSON.parse(localStorage.getItem("premiApp"));

    localStorage.setItem("premiApp", JSON.stringify({ ...loc, id: 0 }));
    localStorage.removeItem("DataRem");
  };

  const singout = () => {
    refDatas_out();
    localStorage.setItem("infoConn", JSON.stringify(inshState));

    refreshPage();
  };

  const { fetch, fetching, isError } = useRefresh();
  const {
    fetch: backupDb,
    fetching: backupping,
    isError: backError,
  } = useBackupDB();
  // for Sale
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const [openSal, setOpenSal] = React.useState(false);
  const handleClickSal = () => {
    setOpenSal(!openSal);
  };
  const [openSaf, setOpenSaf] = React.useState(false);
  const handleClickSaf = () => {
    setOpenSaf(!openSaf);
  };
  // Acc
  const [openAccount, setOpenAccount] = React.useState(false);
  const handleClickAcc = () => {
    setOpenAccount(!openAccount);
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    subTitle:
      "هذا الإجراء سيقوم بحذف كافة البيانات المخزنة وتسجيل خروجك من التطبيق",
  });
  // cust
  const [openCust, setOpenCust] = React.useState(false);
  const handleClickCust = () => {
    setOpenCust(!openCust);
  };
  // sett
  const [openSett, setOpenSett] = React.useState(false);
  const handleClickSett = () => {
    setOpenSett(!openSett);
  };

  let rr = "right";

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const storageClear = async () => {
    localStorage.clear();
    his.push({ pathname: "/" });
  };
  const goScreen = (path, ob) => {
    his.push({ pathname: "/" + path, state: ob });
  };

  const _list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={toggleDrawer(rr, false)}
    >
      {/* ----------------- */}
      <div className={classes.img}>
        {/* <Avatar alt="ORAX" src={logo} className={classes.large} /> */}

        <Paper style={styles.paperContainer}> </Paper>

        <ListSubheader component="div" id="nested-list-subheader" style={backg}>
          <div>
            <span className="FON2"> اوراكس</span>
            <span className="FON"> سوفت</span>
          </div>
        </ListSubheader>
      </div>
      {/* ----------------- */}

      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
        divider={"false".toString()}
        disabled
      >
        <div className="prim">
          {/* <ListItem button component={Link} to="/Main"> */}
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              {" "}
              <DashboardIcon style={chome} />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontA">{"الرئيسية"}</span>
            </ListItemText>
          </ListItem>
        </div>

        <div className="backprm">
          <ListItem
            button
            component={Link}
            to="/chart"
            disabled={!prmiScr.chart}
          >
            <ListItemIcon>
              {" "}
              <TimelineIcon style={cchart} />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontA">{"تقارير الرسومية"}</span>
            </ListItemText>
          </ListItem>
        </div>
      </List>
      <div className="backg">
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            {" "}
            <AssessmentIcon style={ctaq} />{" "}
          </ListItemIcon>
          <ListItemText>
            <span className="fontA">{"التقارير"}</span>
          </ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem button onClick={handleClickSal}>
          <ListItemIcon>
            {" "}
            <LocalMallOutlinedIcon style={csal} />{" "}
          </ListItemIcon>

          <ListItemText>
            <span className="fontB">{"تقارير المبيعات"}</span>
          </ListItemText>

          {openSal ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSal} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/ReporteveryDay"
              disabled={!prmiScr.evrDay}
            >
              <ListItemIcon>
                {" "}
                <ArrowLeftRoundedIcon />{" "}
              </ListItemIcon>
              <ListItemText>
                <span className="fontZ">{"تقرير الحركة اليومية"}</span>
              </ListItemText>
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/ReportEqfalEveryDay"
              disabled={!prmiScr.eqfDay}
            >
              <ListItemIcon>
                {" "}
                <ArrowLeftRoundedIcon />{" "}
              </ListItemIcon>
              <ListItemText>
                <span className="fontZ">{"تقرير الإقفال اليومي"}</span>
              </ListItemText>
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/Activity"
              disabled={!prmiScr.activ}
            >
              <ListItemIcon>
                {" "}
                <ArrowLeftRoundedIcon />{" "}
              </ListItemIcon>
              <ListItemText>
                <span className="fontZ">{"تقرير النشاط التجاري"}</span>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickSaf}>
          <ListItemIcon>
            {" "}
            <ShopOutlinedIcon style={csafs} />{" "}
          </ListItemIcon>
          <ListItemText>
            <span className="fontC">{"تقارير المخازن"}</span>
          </ListItemText>
          {openSaf ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSaf} timeout="auto" unmountOnExit>
          <List disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/"
              disabled={!prmiScr.safJrd}
            >
              <ListItemIcon>
                {" "}
                <ArrowLeftRoundedIcon />{" "}
              </ListItemIcon>
              <ListItemText>
                <span className="fontZ">{"كشف الجرد المخزن"}</span>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickAcc}>
          <ListItemIcon>
            {" "}
            <RateReviewIcon style={cacc} />{" "}
          </ListItemIcon>
          <ListItemText style={cacc}>
            {/* <span className="fontAcc"> */}
            {"تقارير الحسابات"}
            {/* </span> */}
          </ListItemText>
          {openAccount ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAccount} timeout="auto" unmountOnExit>
          <List disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/ReportAcc"
              disabled={!prmiScr.acc}
            >
              <ListItemIcon>
                {" "}
                <ArrowLeftRoundedIcon />{" "}
              </ListItemIcon>
              <ListItemText>
                <span className="fontZ">{"كشف الحساب"}</span>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </Collapse>

      <div className="backprmlastameel">
        <ListItem button onClick={handleClickCust}>
          <ListItemIcon>
            {" "}
            <PeopleAltOutlinedIcon style={ccust} />{" "}
          </ListItemIcon>
          <ListItemText>
            <span className="fontA ziseA">{"العملاء والموردين"}</span>
          </ListItemText>
          {openCust ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </div>
      <Collapse in={openCust} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => goScreen("HesabCustA", inshs.Customers[2])}
            disabled={!prmiScr.custAcc}
          >
            <ListItemIcon>
              {" "}
              <ArrowLeftRoundedIcon />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"كشف حساب عميل"}</span>
            </ListItemText>
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            onClick={() => goScreen("HesabCustM", inshs.Customers[3])}
            disabled={!prmiScr.custAcc}
          >
            <ListItemIcon>
              {" "}
              <ArrowLeftRoundedIcon />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"كشف حساب مورد"}</span>
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>

      <div className="backprmlast">
        <ListItem button onClick={handleClickSett} disabled={!sting}>
          <ListItemIcon>
            {" "}
            <SettingsIcon style={csett} />{" "}
          </ListItemIcon>
          <ListItemText>
            <span className="fontA">{"إعدادات النظام"}</span>
          </ListItemText>
          {openSett ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </div>
      <Collapse in={openSett} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/casher"
          >
            <ListItemIcon>
              {" "}
              <ArrowLeftRoundedIcon />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"  " + " إعدادات الفواتير"}</span>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/SettingsP"
          >
            <ListItemIcon>
              {" "}
              <ArrowLeftRoundedIcon />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"كبير " + " فواتير"}</span>
            </ListItemText>
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/Sett"
          >
            <ListItemIcon>
              {" "}
              <ArrowLeftRoundedIcon />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"الإعدادات"}</span>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/Refresh"
          >
            <ListItemIcon>
              {" "}
              <ArrowLeftRoundedIcon />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"جلب البيانات"}</span>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              // backupDb();
              his.push({ pathname: "/backup" });
            }}
            disabled={backupping}
          >
            <ListItemIcon>
              {" "}
              <ArrowLeftRoundedIcon />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"عمل نسخة احتياطية"}</span>
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
      <div className="backprm">
        <ListItem
          button
          className={classes.nested}
          onClick={() => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: true,
              onConfirm: storageClear,
            });
          }}
        >
          <ListItemIcon>
            {" "}
            <DeleteOutline style={cchart} />{" "}
          </ListItemIcon>
          <ListItemText>
            <span className="fontA">{"مسح التخزين"}</span>
          </ListItemText>
        </ListItem>
      </div>
      <List component="div">
        <span className="sinout">
          <ListItem button className={classes.nested} onClick={singout}>
            <ListItemIcon>
              {" "}
              <LockOutlinedIcon style={ccust} />{" "}
            </ListItemIcon>
            <ListItemText>
              <span className="fontZ">{"تسجيل الخروج"}</span>
            </ListItemText>
          </ListItem>
        </span>
      </List>
    </div>
  );

  return (
    // showSc?<></>:
    <div
      disableBackdropClick
      disableEscapeKeyDown
      onClose={toggleDrawer(rr, false)}
      // onClose={toggleDrawer(rr, false)}
    >
      <DeleteConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Toaster />
      <LoadingRefresh
        open={fetching || backupping}
        msg={
          fetching
            ? "جاري تحديث البيانات "
            : "يتم اخذ نسخة احتياطية يرجى الإنتظار"
        }
        onclose={() => {}}
      />
      <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={clickinfoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle style={{ direction: "rtl" }} id="alert-dialog-slide-title">
          {"معلومـات"}
        </DialogTitle>
        <DialogContent
          style={{ textAlign: "center", color: "green", padding: "8px 10px" }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "green" }}
          >
            {sto.usernames} : المستخدم
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "#84adea" }}
          >
            الفرع : {sto.branchNames}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            النسخة رقم : 2.7
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "indianred" }}
          >
            مؤسسة : {sto.found}
            {/* نسخة مرخصة لــ {sto.found} */}
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "#000" }}
          >
            OraxPro
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {`${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`}
          </DialogContentText>
          {/* <DialogContentText id="alert-dialog-slide-description" style={{fontSize:'10px'}}>
           . تاريخ نهاية العقد . | تاريخ بداية العقد 
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={clickinfoClose} color="primary">
            شكراً
          </Button>
        </DialogActions>
      </Dialog>

      <React.Fragment
        key={rr}
        // onClose={toggleDrawer(rr, false)}
      >
        <SwipeableDrawer
          anchor={rr}
          open={state[rr]}
          onClose={toggleDrawer(rr, false)}
          onOpen={toggleDrawer(rr, true)}
        >
          {_list(rr)}
        </SwipeableDrawer>
      </React.Fragment>

      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Typography style={{ marginRight: "-4px", zIndex: 1301 }}>
          <Toolbar variant="dense">
            <Hidden smUp={["sm"]}>
              <IconButton
                component={Link}
                to="/"
                variant="contained"
                edge="start"
                color="inherit"
                aria-label="home"
              >
                <HomeIcon />
                {"  "}
              </IconButton>

              <IconButton
                onClick={clickinfoOpen}
                variant="contained"
                edge="start"
                color="inherit"
                aria-label="home"
              >
                <HelpIcon style={cchart} />
                {"  "}
              </IconButton>
              <IconButton
                onClick={() => {
                  his.push({ pathname: "/chart" });
                  window.location.reload(false);
                }}
                variant="contained"
                edge="start"
                color="inherit"
                aria-label="Dashboard"
              >
                <TimelineIcon style={cchart} />
                {"  "}
              </IconButton>
              <IconButton
                onClick={fetch}
                variant="contained"
                edge="start"
                color="inherit"
                aria-label="Refresh"
              >
                <Refresh style={cchart} />
                {"  "}
              </IconButton>
            </Hidden>

            <Hidden only={["xs"]}>
              <Button
                variant="contained"
                className={classes.button}
                component={Link}
                to="/"
                color="primary"
                size="large"
                startIcon={<HomeIcon />}
              >
                {" "}
                {t("main-menu-title")}
              </Button>

              <Button
                variant="contained"
                className={classes.button}
                onClick={clickinfoOpen}
                color="primary"
                size="large"
                startIcon={<HelpIcon />}
              >
                {" "}
                معلومات المستخدم{" "}
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                disabled={!pScr.acc}
                onClick={() => {
                  his.push({ pathname: "/chart" });
                  window.location.reload(false);
                }}
                color="primary"
                size="large"
                startIcon={<TimelineIcon style={cchart} />}
              >
                {" "}
                Dashboard{" "}
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                disabled={fetching}
                onClick={fetch}
                color="primary"
                size="large"
                startIcon={<Refresh style={cchart} />}
              >
                {/* Refresh{" "} */}
              </Button>

              <div>
                <SelectLanguage />
              </div>
            </Hidden>
            <div className={classes.grow} />

            <IconButton edge="start" color="inherit" aria-label="open drawer">
              <MenuIcon onClick={toggleDrawer(rr, true)} />
            </IconButton>

            {/* <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
          <IconButton edge="end" color="inherit">
            <MoreIcon />
          </IconButton> */}
            {/* <IconButton 
           component={Link} to="/Main"
          variant="contained" edge="start" color="inherit" aria-label="home">
              <HomeIcon />
            </IconButton> */}
          </Toolbar>
        </Typography>
      </AppBar>
    </div>
  );
}
