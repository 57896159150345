import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import PrintIcon from "@material-ui/icons/Print";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import {
  useStylesContainers,
  useStylesHed,
  useStylesBA,
  useStylesCAB,
} from "../../../../St/comps/UseStyle";
import GroupsForm from "./groups-form";
import GroupsTable from "./groups-table";
import { stElemnt } from "../../../../St/comps/cStyle";
import toast, { Toaster } from "react-hot-toast";
import { insh as inshst } from "../../../../Context/InshSt";
import GroupAPI, { defaultDG } from "../../../../api/Group";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import { sto } from "../../../../Context/InshData";

export default function Groups(props) {
  const { fetchURL } = props;
  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    GroupAPI.create,
    GroupAPI.update,
    GroupAPI.remove,
    undefined,
    defaultDG,
    fetchURL,
    fetchURL
  );

  let his = useHistory();

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasCAB = useStylesCAB();

  const [style, setStyle] = useState(inshst.bascData.all);

  const onSubmitHandle = (data) => {
    if (data.name != "") {
      let parent_id = 0;
      if (object.type == "sub") parent_id = object.parent.id;
      // const group_Emps = data.group_Emps.map((se) => {
      //   return { id: se.id, group_id: 0 };
      // });
      let nData = {
        ...data,
        parent_id: parent_id,
        branch: sto.branchi,
        // group_Emps: group_Emps,
      };
      if (formType == "edit") update(nData);
      else submit(nData);
      console.log(" the sumbitted data is ", nData);
    } else toast.error("ادخل اسم المجموعة");
  };

  const backHome = () => {
    his.push({ pathname: "/" }); /*console.log('stooping')*/
  };
  const back = () => his.goBack();

  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    // paddingBottom: "100px",
    direction: "rtl",
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="sm" style={{ paddingBottom: "100px" }}>
        <Toaster />
        <Typography component="div" style={styheader}>
          <Grid item container>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={"مجموعات الأصناف"}
                color="primary"
              />
            </Grid>
            <Grid container xs={12}>
              <Grid
                item
                container
                lg={12}
                md={12}
                className={[clsConts.rootup]}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  xs={12}
                  className={[clsConts.subUp, clsConts.subUpC]}
                >
                  <GroupsForm
                    {...{
                      ...props,
                      onChange,
                      submit,
                      update,
                      remove,
                      submitting,
                      errorMessage,
                      onShow,
                      onEnd,
                      object,
                      formType,
                    }}
                  />
                </Grid>
              </Grid>
              <GroupsTable
                {...{
                  ...props,
                  onChange,
                  submit,
                  update,
                  remove,
                  submitting,
                  errorMessage,
                  onShow,
                  onEnd,
                  object,
                  formType,
                }}
              />
            </Grid>
          </Grid>

          <React.Fragment>
            <CssBaseline />
            <AppBar
              position="fixed"
              color="primary"
              style={{
                ...style.bgImg,
                ...stElemnt.all.appBarBA,
                direction: "ltr",
              }}
            >
              <Toolbar className={clasBA.ToolbarBA} variant="dense">
                <Hidden smUp={["sm"]}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => backHome()}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => back()}
                  >
                    <ReplyIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={""}
                  >
                    <PrintIcon />
                  </IconButton>
                </Hidden>

                <Hidden only={["xs"]}>
                  <Button
                    style={{ color: "#fff" }}
                    onClick={() => backHome()}
                    startIcon={<CloseIcon />}
                  >
                    خروج
                  </Button>
                  <Button
                    style={{ color: "#fff" }}
                    onClick={() => back()}
                    startIcon={<ReplyIcon />}
                  >
                    رجوع
                  </Button>
                </Hidden>

                <div className={clasBA.growBA} />

                <Hidden smUp={["sm"]}>
                  {/* ico left  */}
                  <div className={clasCAB.wrapperCAB}>
                    <Fab
                      size="medium"
                      color="primary"
                      onClick={() => onSubmitHandle(object)}
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    >
                      {<SaveIcon />}
                    </Fab>
                    {submitting && (
                      <CircularProgress
                        size={56}
                        className={clasCAB.fabProgressCAB}
                      />
                    )}
                  </div>

                  <div className={clasCAB.wrapperCAB}>
                    <Fab
                      size="medium"
                      color="primary"
                      onClick={() => onShow(defaultDG, "new")}
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    >
                      {<AddIcon />}
                    </Fab>
                    {submitting && (
                      <CircularProgress
                        size={56}
                        className={clasCAB.fabProgressCAB}
                      />
                    )}
                  </div>
                </Hidden>
                {formType == "edit" ? (
                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<DeleteIcon />}
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      disabled={submitting}
                      onClick={(e) => remove(object.id)}
                    >
                      حـذف
                    </Button>
                    {submitting && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>
                ) : null}

                <Hidden only={["xs"]}>
                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      disabled={submitting}
                      onClick={() => onSubmitHandle(object)}
                      startIcon={<SaveIcon />}
                    >
                      {" "}
                      {"حـفـظ"}{" "}
                    </Button>
                    {submitting && (
                      <CircularProgress
                        size={24}
                        className={clasCAB.buttonProgressCAB}
                      />
                    )}
                  </div>

                  <div className={clasCAB.wrapperCAB}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                      onClick={() => onShow(defaultDG, "new")}
                      startIcon={<AddIcon />}
                    >
                      {" "}
                      {"جـديـد"}{" "}
                    </Button>
                  </div>
                </Hidden>
              </Toolbar>
            </AppBar>
          </React.Fragment>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
