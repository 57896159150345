import moment from "moment";
import * as _ from "lodash";

export function toString(ob) {
  Object.keys(ob).forEach((k) => {
    if (typeof ob[k] === "object") {
      return toString(ob[k]);
    }
    ob[k] = "" + ob[k];
  });
  return ob;
}

export function reFormatDate(date) {
  return moment(date).format("YYYY-MM-DD");
}


