import { GroupSelect } from "../../../../components/invoice/group-select";
import { getLS } from "../../../../utils/useLS";
import { createStateContext } from "react-use";
import React, { useEffect } from "react";
import { useState } from "react";
import ProductsGrid from "../../../../components/invoice/item-grid";
import SearchBox from "../../../../components/invoice/search-box";
import { colors, Divider, Paper } from "@material-ui/core";

export const [useSharedGroupId, SharedGroupIdProvider] = createStateContext(-1);

export function Products(props) {
  const { dataTable } = props;
  const locSto = getLS("sto", {});

  const [groupId, setGroupId] = useState(-1);
  const [subGroupId, setSubGroupId] = useState(-1);
  const [subGroups, setSubGroups] = useState([]);

  const changeMainGroupHandle = (id) => {
    setGroupId(id);
    const subs = locSto?.groupsSub?.filter((g) => g.i == id);
    setSubGroups(subs);
    if (subs?.length > 0) setSubGroupId(subs[0]?.id);
    else setSubGroupId(id);
  };
  useEffect(() => {
    console.log("the data is", locSto.groupsSub);
  }, [subGroupId, dataTable]);
  return (
    <React.Fragment>
      <Paper
        style={{
          // backgroundColor: colors.green[50],
          maxHeight: 500,
          overflow: "auto",
        }}
      >
        <SharedGroupIdProvider>
          {/* <SearchBox /> */}

          <GroupSelect
            data={[...locSto.groups]}
            isMain={true}
            //   subs={locSto.groupsSub}
            groupId={groupId}
            setGroupId={changeMainGroupHandle}
          />
          <Divider />
          {groupId != -1 && (
            <GroupSelect
              data={subGroups}
              //   subs={locSto.groupsSub}

              groupId={subGroupId}
              setGroupId={setSubGroupId}
            />
          )}

          <ProductsGrid
            onSelectItem={props.onSelectItem}
            values={dataTable}
            groupId={subGroupId}
            BefrowDataCurrencie={null}
            
          />
        </SharedGroupIdProvider>
      </Paper>

      {/* <Swipeable /> */}
    </React.Fragment>
  );
}
