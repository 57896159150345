import React, { useEffect } from 'react';

import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';

import {useRptAF} from '../../../../St/Reviews/UseStyle';
// import { Paper } from '@material-ui/core';
import MaterialTable from "material-table";
import { makeStyles } from '@material-ui/core/styles';
// import { stElemnt } from '../../../St/Reviews/cStyle';
import { stElemnt } from '../../../../St/Reviews/cStyle';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

  itemW: {
    border: 'solid 1px ',
    borderColor:'black',
    textAlign: 'center',
    padding:2,
    marginBottom:2,
    fontSize: '0.6rem',
    //  fontWeight:'700',
    color:'red',
    fontWeight: 'bold',
    
  },
  item2: {
    border: 'solid 1px ',
    borderColor:'black',
    textAlign: 'center',
    fontSize: '0.8rem',
    //  fontWeight:'700',
    fontWeight: 'bold',
  },
}));

function BodyA() {
  const cls = useRptAF();
  const classes = useStyles();
  const loc = useLocation();
  const data = loc.state.data;
  const totals = loc.state.totals;
var columns = [
  { title: "الاسم", field: "txtType" },
  { title: " عدد الفواتير", field: "cnt" },
  { title: " صافي الكمية", field: "qty", },
  { title: " صافي الاجمالي", field: "totNet",  },
  { title: " صافي الخصم", field: "minus",  },
  { title: "  صافي الضريبة", field: "vat",  },
  { title: "صافي التكلفة", field: "cost" , },
  { title: "صافي الربح", field: "Profit" , },
]

return (
<Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
        <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: stElemnt.AF.header,
            cellStyle: stElemnt.AF.cell,
            // maxBodyHeight: '28.5rem',
            // minBodyHeight: '28.5rem',
           }}
             
          localization={{ body:{ emptyDataSourceMessage:<h1></h1> ,}, }}
          
              columns={columns}
              data={data}
             />
        </Grid>
      </Grid>

      <Grid container lg={12} md={12} className={cls.rootBdydown}>
         <Grid container xs={12}  /*className={cls.rootchip}*/>
            <Grid item xs={2}  className={classes.itemW} >{' صافي الكميات '}</Grid>
            <Grid item xs={2} className={classes.itemW}>{' صافي الاجمالي '}</Grid>
            <Grid item xs={2} className={classes.itemW}>{' صافي الخصم '}</Grid>
            <Grid item xs={2} className={classes.itemW}>{' صافي الضريبة '}</Grid>
            <Grid item xs={2} className={classes.itemW}>{' صافي التكلفة '}</Grid>
            <Grid item xs={2} className={classes.itemW}>{' صافي الربح '}</Grid>
            {/* <Grid item xs={2} className={classes.itemW}>{' صافي الكميات '}</Grid> */}
            {/* <Grid item xs={2} className={[cls.chipV,cls.chipVR]}>{Rept.tot.totval}</Grid> */}
          </Grid>
          <Grid container xs={12} /*className={cls.rootchip}*/>
            <Grid item xs={2} className={classes.item2}>{totals.qtyTotal.toFixed(2)}</Grid>
            <Grid item xs={2} className={classes.item2}>{totals?.totNetTotal.toFixed(2)}</Grid>
            <Grid item xs={2} className={classes.item2}>{totals?.minusTotal.toFixed(2)}</Grid>
            <Grid item xs={2} className={classes.item2}>{totals?.vatTotal.toFixed(2)}</Grid>
            <Grid item xs={2} className={classes.item2}>{totals?.costTotal.toFixed(2)}</Grid>
            <Grid item xs={2} className={classes.item2}>{totals?.profitTotal.toFixed(2)}</Grid>
          </Grid>
      </Grid>

</Grid>
   );

  }

export default BodyA;
