import axios from "axios";
import { uri } from "../help/Api";

export const GROUPS_URL = uri + "Groups";
export const defaultDG = {
  id: "",
  name: "",
  nameEN: "",
  group_id: "",
  parent_id: 0,
  type: "main",
  group_Emps: [],
  parent: { id: 0, name: "" },
};

export function list() {
  return axios.get(GROUPS_URL);
}

export function create(data) {
  return axios.post(GROUPS_URL, data);
}

export function update(data) {
  return axios.put(GROUPS_URL, data);
}
export function remove(id) {
  return axios.delete(GROUPS_URL + `/${id}`);
}

export default { list, create, update, remove };
