import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import Barcode from "react-barcode/lib/react-barcode";

function BarcodeGenerator(props) {
  const { barcode, itemName, price } = props;

  return (
    <div
      style={{ textAlign: "center", backgroundColor: "#fff", padding: "10px" }}
    >
      <Typography
        style={{
          padding: "0",
          fontSize: "9px",
          marginBottom: "-3px",
          // zIndex: 100,
        }}
      >
        {itemName}
      </Typography>
      <Barcode
        id="mybarcode"
        value={barcode}
        background="#ffffff"
        lineColor="black"
        width="1"
        height="20"
        format="CODE128"
        displayValue="true"
        font="monospace"
        textAlign="center"
        textPosition="bottom"
        textMargin="3"
        fontSize="10"
        marginTop="0"
        marginBottom="0"
      />
      <Typography
        style={{
          direction: "rtl",
          marginTop: "-10px",
          // fontSize: "10px",
          fontWeight: "bold",
          backgroundColor: "transparent",
          padding: "0",
        }}
        // color={"primary"}
      >
        {" "}
        {price} {"رس  "}
      </Typography>
    </div>
  );
}

export default BarcodeGenerator;
